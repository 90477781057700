let swiperInitialized = false;

// Function to load Swiper
const lazyLoadSwiper = async () => {
    if (swiperInitialized) return;
    swiperInitialized = true;

    try {
        const { default: Swiper } = await import('swiper/bundle');
        // await // Lazy load Swiper styles

        const swiperContainers = document.querySelectorAll(".swiper-container");
        swiperContainers.forEach((container) => {
            const pagination = container.querySelector('.swiper-pagination');
            const nextButton = container.querySelector('.swiper-button-next');
            const prevButton = container.querySelector('.swiper-button-prev');

            if (container.classList.contains('swiper-casestudy')) {
              new Swiper(container, {
                slidesPerView: 1,
                loop: true,
                spaceBetween: 30,
                centeredSlides: true,
                autoplay: {
                  delay: 10000,
                  disableOnInteraction: false,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                  768: {
                    slidesPerView: 1.7,
                  },
                },
              });
            }
            if (container.classList.contains('swiper-service-924')) {
              new Swiper(container, {
                slidesPerView: 1,
                loop: true,
                spaceBetween: 25,
                autoplay: {
                  delay: 7000,
                  disableOnInteraction: false,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                  1400: {
                    slidesPerView: 4,
                  },
                  1024: {
                      slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                },
              });
            }
        });
    } catch (error) {
        console.error('Failed to load Swiper:', error);
    }
};

// Intersection Observer to check if swiper-container is in viewport
const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            lazyLoadSwiper();
            // Stop observing once loaded
            observer.unobserve(entry.target);
        }
    });
});

// Observe all swiper-container elements
const swiperContainers = document.querySelectorAll('.swiper-container');
swiperContainers.forEach(container => {
    observer.observe(container);
});
