import "./lazyload-image.js";
import "./lazyload-video.js";
import "./swiper.js";
import "./swiper-new.js";
import "lightbox2/dist/css/lightbox.min.css";
import "./modal.js";
import "./collapse.js";
import "./lightbox.js";
import "./tooltip.js";
import "./pricing-page-new.js";
import "./typed.js";

document.addEventListener("DOMContentLoaded", function () {
  // Trì hoãn việc tải các module không khẩn cấp
  function runWhenIdle(callback) {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(callback);
    } else {
      // Fallback cho các trình duyệt không hỗ trợ requestIdleCallback
      setTimeout(callback, 1000);
    }
  }

  runWhenIdle(() => {
    // Các import khác giữ nguyên
    import("./copy-clipboard.js");
    import("./smooth-scroll").then(({ scroll }) => {
      // Khởi tạo hoặc sử dụng scroll
    });

    const YoutubePopup = document.querySelectorAll("a.bla-1");
    if (YoutubePopup) {
      import("./YouTubePopUp.js");
    }

    // Trì hoãn import live-demo-submit.js trong 10 giây
    setTimeout(() => {
      import("./live-demo-submit.js").then(() => {
        console.log("live-demo-submit.js đã được tải sau 10 giây");
      });
      import("./live-demo-modal-submit.js").then(() => {
        console.log("live-demo-modal-submit.js đã được tải sau 10 giây");
      });
    }, 3000);

    // Play video demo
    const playVideoDemo = document.getElementById("play-video-demo");
    if (playVideoDemo) {
      playVideoDemo.addEventListener("click", function (event) {
        event.preventDefault();
        const player = new Vimeo.Player(document.getElementById("video-demo"));
        player.play();

        document.getElementById("video-demo").classList.remove("hidden");
        document.getElementById("image-video").classList.add("hidden");

        setTimeout(() => {
          document.getElementById("show-code").classList.add("active");
        }, 3000);
      });
    }

    // Popover live demo with event delegation
    const popover = document.querySelector(".popover");
    const wrapper = document.querySelector(".wrapper-popover");
    if (popover && wrapper) {
      document.body.addEventListener("click", function (event) {
        if (event.target.closest(".trigger-popover")) {
          console.log("trigger-popover");
          popover.classList.toggle("show-popover");
          wrapper.classList.toggle("active");
        } else if (
          event.target.matches(".close-button-popover") ||
          event.target === popover
        ) {
          popover.classList.remove("show-popover");
          wrapper.classList.remove("active");
        }
      });
    }

    // Modal live demo with event delegation
    const modal = document.querySelector(".modal-demo");
    if (modal) {
      document.body.addEventListener("click", function (event) {
        if (event.target.matches(".trigger")) {
          modal.classList.toggle("show-modal");
          document.body.style.overflow = "hidden";
        } else if (
          event.target.matches(".close-button") ||
          event.target === modal
        ) {
          modal.classList.remove("show-modal");
          document.body.style.overflow = "auto";
        }
      });
    }
  });

  // Tối ưu toggleMenu với caching
  window.toggleMenu = function () {
    const menu = document.querySelector(".menu-mobile");
    const burger = document.getElementById("burger-mobile");
    const body = document.body;

    if (burger) burger.classList.toggle("open");
    if (menu) menu.classList.toggle("active");
    body.style.overflow = body.style.overflow === "hidden" ? "auto" : "hidden";
  };
  const activateItem = (item) => {
    document.querySelectorAll(".item-4000.active").forEach((activeItem) => {
      const activeVideo = activeItem.querySelector("video");
      activeItem.classList.remove("active");
      activeVideo.pause();
    });
    item.classList.add("active");
    item.querySelector("video").play();
  };
  
  const deactivateItem = (item) => {
    item.classList.remove("active");
    item.querySelector("video").pause();
  };
  
  // Function to handle video actions based on screen width
  const handleVideo = () => {
    const isMobile = window.innerWidth < 768;
    document.querySelectorAll(".item-4000").forEach((item) => {
      const video = item.querySelector("video");
  
      if (isMobile) {
        // Autoplay for mobile
        video.play();
      } else {
        // Desktop: Play/pause on hover/touch
        item.addEventListener("mouseenter", () => activateItem(item));
        item.addEventListener("mouseleave", () => deactivateItem(item));
        item.addEventListener("touchstart", () => activateItem(item));
        item.addEventListener("touchend", () => deactivateItem(item));
        item.addEventListener("touchcancel", () => deactivateItem(item));
      }
    });
  };
  
  // Trigger video actions on page load and screen resize
  window.addEventListener("load", handleVideo);
  window.addEventListener("resize", handleVideo);
  
  // Auto-play the first video when target enters the viewport
  const target = document.getElementById("4000-companies-choose");
  if (target) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const firstItem = document.querySelector(".item-4000");
            if (firstItem) activateItem(firstItem);
          }
        });
      },
      { threshold: 0.5 }
    );
    observer.observe(target);
  }

  /* 
    Lazyload image and placeholder image
  */
  const imgElements = document.querySelectorAll("img[data-srcset], img[data-src]");
  const lazyLoadingImage = (entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;
      // Assign data-srcset to srcset and data-src to src
      if (entry.target.dataset.srcset)  entry.target.srcset = entry.target.dataset.srcset;
      entry.target.src = entry.target.dataset.src; // optional, if needed for initial src
      
      entry.target.addEventListener("load", () => {
        observer.unobserve(entry.target); // Stop observing after loading
      });
      
      console.log("Image loaded");
    });
  };
  const lazyLoadingObserver = new IntersectionObserver(lazyLoadingImage, {
    threshold: 0.6,
  });
  imgElements.forEach((img) => lazyLoadingObserver.observe(img));
  /*
    double list
  */
  document.querySelectorAll('.scroll-infinite .items').forEach(list => {
    if(list){
      list.querySelectorAll('.scroll-infinite .items .item').forEach(item => {
        try {
          list.appendChild(item.cloneNode(true));
        } catch (e) {
          console.error('Error cloning item:', e);
        }
      });
    }
  });
  // add class to header navbar when scroll
  var menu = document.getElementById('header-navbar');
  var spacer = document.getElementById('spacer');
  var observer = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
      if (!entry.isIntersecting) {
        menu.classList.add('scrolled');
      } else {
        menu.classList.remove('scrolled');
      }
    });
  }, { threshold: 0.1 });
  observer.observe(spacer);

  // set margin WordPress admin bar if present
  const wpAdminBar = document.getElementById('wpadminbar');
  if (wpAdminBar) {
    document.documentElement.style.setProperty('margin-top', '0px', 'important'); 
  }




});
