
document.addEventListener("DOMContentLoaded", () => {
  
  const typedSpan = document.getElementById("typed");
  if(!typedSpan) return;
  
  const totype = ["ad designer", "illustrator", "web designer", "motion artist", "pitch deck designer", "video editor"];
  const [delayTyping_char, delayErasing_text, delayTyping_text] = [50, 150, 600];
  let [totypeIndex, charIndex] = [0, 0];

  function typeText() {
    typedSpan.textContent += totype[totypeIndex].charAt(charIndex++);
    charIndex < totype[totypeIndex].length 
      ? setTimeout(typeText, delayTyping_char)
      : setTimeout(eraseText, delayTyping_text);
  }

  function eraseText() {
    typedSpan.textContent = totype[totypeIndex].substring(0, --charIndex);
    charIndex > 0
      ? setTimeout(eraseText, delayErasing_text)
      : setTimeout(() => {
        totypeIndex = (totypeIndex + 1) % totype.length;
        setTimeout(typeText, delayTyping_text);
      }, delayTyping_text);
  }

  setTimeout(typeText, delayTyping_text);
  
});