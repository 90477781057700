document.addEventListener("DOMContentLoaded", () => {
  const lazyBackgrounds = document.querySelectorAll('.lazy-background');

  const loadBackground = (element) => {
      const bgImage = element.getAttribute('data-bg');
      element.style.backgroundImage = `url('${bgImage}')`;
      element.classList.add('lazy-loaded');
      element.classList.remove('lazy-background');
  };

  if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
              if (entry.isIntersecting) {
                  loadBackground(entry.target);
                  observer.unobserve(entry.target);
              }
          });
      }, { rootMargin: '300px' });

      lazyBackgrounds.forEach((lazyBackground) => observer.observe(lazyBackground));
  } else {
      lazyBackgrounds.forEach(loadBackground);
  }
  
});
