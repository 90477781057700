document.addEventListener('DOMContentLoaded', function () {
    const lazyLoadHandyCollapse = () => {
        console.log('test handy')
      import("handy-collapse").then(({ default: HandyCollapse }) => {
        // Khởi tạo HandyCollapse
        console.log('test handy')
        new HandyCollapse({ 
        });
        const tab = new HandyCollapse({
          nameSpace: "tab",
          activeClass: "active",
          closeOthers: true,
          isAnimation: false
        });
  
        const faq = new HandyCollapse({
          nameSpace: "hc",
          activeClass: "is-active",
          closeOthers: true,
          isAnimation: true,
          onSlideStart: (isOpen, contentID) => {
            console.log(isOpen);
            const buttonEl1 = document.querySelectorAll(`[data-hc-control]`);
            buttonEl1.forEach(element => {
              element.parentNode.classList.remove('bg-gradient-1');
            });
            if(isOpen) {
              const buttonEl = document.querySelectorAll(`[data-hc-control='${contentID}']`);
              buttonEl.forEach(element => {
                element.parentNode.classList.add('bg-gradient-1');
              });
            }
          },
        });
  
        // Xóa observer sau khi đã load script
        observer.disconnect();
      }).catch(err => {
        console.error("Failed to load HandyCollapse", err);
      });
    };
    // Sử dụng IntersectionObserver để lazy load
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          lazyLoadHandyCollapse();
          observer.disconnect();
        }
      });
    });
  
    // Quan sát phần tử có class .handy-collapsible-container
    const targetElement = document.querySelector('.handy-collapsible-container');
    if (targetElement) {
      observer.observe(targetElement);
    }
  });
  